import React from "react";
import { ThemeProvider } from "styled-components";
import { useParams } from 'react-router-dom';
import { gql, useQuery } from "@apollo/client";

import Header from "../../molecules/Header";
import TagArticleList2ColumnWithSideBarBlock from "../../organisms/TagArticleList2ColumnWithSideBarBlock";
import RecommendBlock from "../../organisms/RecommendBlock";
import TopicsBlock from "../../organisms/TopicsBlock";
import MainTemplate from "../../templates/MainTemplate";
import Consts from "../../../config/Consts";
import Nav from "../../molecules/Nav";
import addslashes from "../../../utils/AddSlashes";
import RectangleAd from "../../ads/RectangleAd";
import adid from "../../../config/adid";

const GET_TAG_LIST = gql`
  query {
    tag_list {
      topic_id,
      label
    }
  }
`;

const TagPage = ({post_client}) => {
  let { topic_id } = useParams();

  const { loading, error, data } = useQuery(GET_TAG_LIST);
  if (loading) return null;
  if (error) return null;

  let label = '';
  for(let tag of data.tag_list) {
    if(tag.topic_id === topic_id) {
      label = tag.label;
      break;
    }
  }
  if(label.length === 0) {
    throw new Error('NOTFOUND');
  }
  const target_topic_id = Consts.ID.EXCHANGE_TAG[topic_id] ? Consts.ID.EXCHANGE_TAG[topic_id] : topic_id

  const title = label + 'の記事一覧｜THE NIKKEI MAGAZINE｜日本経済新聞社'
  const description = label + 'のページ一覧です。' + Consts.COMMON.DESCRIPTION
  const analyticsVars = 'var analyticsVars = {'
  + 'title:"' + addslashes(title) + '"'
  + ',tag_id:["' + topic_id + '"]'
  + ',tag_name:["' + label + '"]'
  + '};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="description" content={description} />,
    <meta key='h-meta-2' property="og:title" content={title} />,
    <meta key='h-meta-3' property="og:description" content={description} />,
    <link key='h-canonical' rel="canonical" href={`${Consts.COMMON.BASE_URL}/tag${topic_id?'/'+topic_id:''}`} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]
  const verification = {
    label: label+"の記事一覧", 
    path: Consts.COMMON.BASE_URL + "/tag/" + topic_id
  }

  const mode = 'default';
  const page = 'article-index';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        custom_key_param={{topics:[topic_id]}}
        helmet={helmet}
        header={
          <Header post_client={post_client} />
        }
        main={
          <>
            <Nav />
            <TagArticleList2ColumnWithSideBarBlock topic_id={target_topic_id} label={label} verification={verification} />
            <TopicsBlock />
            <RectangleAd adid={adid.SP[adpagetype].RECTANGLE2} viewable={true} device="sp" add_margin={true} />
            <RecommendBlock />
            <RectangleAd adid={adid.SP[adpagetype].RECTANGLE3} viewable={true} device="sp" add_margin={true} />
          </>
        }
      />
    </ThemeProvider>
  )
};

export default TagPage;