import React, { useEffect, useCallback, useRef, useState } from "react";
import PropTypes from 'prop-types';
import useIsVisible from '../../../hooks/useIsVisible';

const RectangleAd = ({
  adid,
  double = () => { }, // ダブルレクタングルのときに呼ばれる
  noad = () => { }, // 広告がないときに呼ばれる
  viewable = true, // viewable指定 default on
  device,
  scroller = false,
  add_margin = false
}) => {

  const adDiv = useRef();
  const container = useRef();
  const isVisible = useIsVisible(container, {once: true});

  useEffect(() => {
    if(isVisible) {
      loadAd();
      showAd();
    }
  }, [isVisible]);
  const loadAd = useCallback(() => {
    if (adid) {
      if (typeof TagProvider !== "undefined") {
        try {
          const options = viewable === false ? {
            sectionId: adid.SECTION,
            dmp: true,
            sync: false
          } :
            {
              sectionId: adid.SECTION,
              dmp: true,
              sync: false,
              viewable: { slot: adid.SLOT, percent: 0.1, reload: 1 }
              // handler: (a) => {
              //   /*global process_tag_provider_response_11516 */
              //   // process_tag_provider_response_11516(a);
              //   // TagProvider.displayAd(`${adid.SLOT}_${adid.SECTION}`);
              //   console.log('hander', a)
              //   // if(a.length < 0) {
              //   //   section.current.remove();
              //   // }
              //   return a;
              // }
            };
          /*global TagProvider */
          TagProvider.requestAds(options);
        } catch (err) {
        }

        try {
          TagProvider.displayAd(`${adid.SLOT}_${adid.SECTION}`);
        } catch (err) {
        }
      }
    }
  });
  const showAd = () => {
    const listener = () => {
      // レクタングルは iframe で表示されることが前提
      const ad = adDiv.current.querySelector("iframe");
      // 広告のiframeがない
      if (ad === null) {
        noad.apply();
        removeListner();
      }
      // 広告の高さが300以上ならダブルレクタングルとみなす
      // 使用ではシングルは250、ダブルは600
      else if (parseInt(ad.style.height) > 300) {
        double.apply();
        removeListner();
      }
      if(scroller) {
        // 100ミリ秒後に確認し(iframe読み込み後に操作されるため)
        // 広告divのdisplayがnoneの場合も広告がないとみなす
        setTimeout(() => {
          if (adDiv.current.style.display === 'none') {
            noad.apply();
            removeListner();
          }
        }, 100);
      }
    }
    // MutationObserverのインスタンスを作成
    let observer = new MutationObserver(function(mutationsList, observer) {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree' || mutation.type === 'attributes') {
          listener();
        }
      }
    });
    const removeListner = () => {
      // 監視の停止 (必要に応じて)
      observer.disconnect();
    }
    // 監視オプション
    let config = { childList: true, subtree: true, attributes: true };
    // 監視を開始
    observer.observe(adDiv.current, config);
  }

  if (adid.SLOT.length === 0) return null;
  let additional_class = device === "sp" ? "p-article-index__ad-sidebar-single m-ad-sidebar-single" : ""
  if(add_margin) additional_class += " m-ad-sidebar-single__margin"
  return (
    <div ref={container} className={`is-${device} ${additional_class}`}
      data-testid={`block-name-${adid.SLOT}_${adid.SECTION}`}>
      {isVisible &&
        <div id={`${adid.SLOT}_${adid.SECTION}`} ref={adDiv} />
      }
    </div>
  );
}

RectangleAd.propTypes = {
  adid: PropTypes.object.isRequired,
  double: PropTypes.func,
  noad: PropTypes.func,
  viewable: PropTypes.bool,
  device: PropTypes.string.isRequired
}

export default RectangleAd;