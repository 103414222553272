import React from "react";
import { ThemeProvider } from "styled-components";
import { useParams } from 'react-router-dom';
import { gql, useQuery } from "@apollo/client";

import AdTop from "../../ads/AdTop";
import Header from "../../molecules/Header";
import NikkeiFooter from '../../molecules/NikkeiFooter';
import NikkeiAiFooter from '../../molecules/NikkeiAiFooter';
import ArticleList2ColumnWithSideBarBlock from "../../organisms/ArticleList2ColumnWithSideBarBlock";
import RecommendBlock from "../../organisms/RecommendBlock";
import TopicsBlock from "../../organisms/TopicsBlock";
import MainTemplate from "../../templates/MainTemplate";
import adid from '../../../config/adid';
import Consts from "../../../config/Consts";
import RectangleAd from "../../ads/RectangleAd";
import HtmlBanner from "../../molecules/HtmlBanner";
import Nav from "../../molecules/Nav";
import addslashes from "../../../utils/AddSlashes";

export const GET_CATEGORY_PATH = gql`
  query (
    $token: ID,
    $category_path: String,
    $sub_topic_id: ID
  ) {
    verification(
      token: $token,
      category_path: $category_path,
      sub_topic_id: $sub_topic_id
    ) {
      topic_id,
      label,
      path,
      sub_topic_id,
      sub_label,
      sub_path
    }
  }
`

const ArticleIndexPage = ({post_client}) => {
  let { category, topic_id } = useParams();

  const { loading, error, data } = useQuery(GET_CATEGORY_PATH, {
    variables: {
      token: "ArticleIndexPage",
      category_path: category ? category : '',
      sub_topic_id: topic_id ? topic_id : ''
    }
  });
  if (loading) return null;
  if (error) return null;

  if(data.verification.topic_id.length === 0 || (topic_id && topic_id.length > 0 && data.verification.sub_topic_id.length === 0)) {
    throw new Error('NOTFOUND');
  }
  const category_topic_id = data.verification.topic_id
  let target_topic_id = category_topic_id
  let label = data.verification.label
  const custom_key_param = {topics:[category_topic_id]}
  if(data.verification.sub_topic_id.length > 0) {
    target_topic_id = data.verification.sub_topic_id
    label = data.verification.sub_label
    custom_key_param.topics.push(target_topic_id)
  }

  const title = label + '｜THE NIKKEI MAGAZINE｜日本経済新聞社'
  let description = ''
  switch(category_topic_id) {
    case 'D0000000':  // Watch & Jewelry
      description = '時計と宝石（ジュエリー）のページ一覧です。'
      break;
    case 'D0000001':  // Fashion
      description = 'ファッションのページ一覧です。'
      break;
    case 'D0000002':  // Health & Beauty
      description = '健康、美容のページ一覧です。'
      break;
    case 'D0000003':  // Sustainable
      description = 'サステナブルのページ一覧です。'
      break;
    case 'D0000004':  // People
      description = '人のページ一覧です。'
      break;
    case 'D0000005':  // Lifestyle
      description = 'ライフスタイルのページ一覧です。'
      break;
    case 'D0000006':  // Culture
      description = '文化のページ一覧です。'
      break;
    case 'D0000009':  // AI
      description = 'THE NIKKEI MAGAZINE Aiのページ一覧です。'
      break;
    default:  // ex.) SPIRE,Information,Promotion
      description = data.verification.label + 'のページ一覧です。'
      break;
  }
  if(data.verification.sub_label.length > 0) {
    description = data.verification.sub_label + "のページ一覧です。"
  }
  description += Consts.COMMON.DESCRIPTION
  let sub_topic_id = ''
  let sub_topic_name = ''
  if(data.verification.sub_topic_id) {
    sub_topic_id = data.verification.sub_topic_id;
    sub_topic_name = addslashes(data.verification.sub_label);
  }
  let analyticsVars = 'var analyticsVars = {'
    + 'topic_id:"' + data.verification.topic_id + '"'
    + ',topic_name:"' + addslashes(data.verification.label) + '"'
    + ',sub_topic_id:"' + sub_topic_id + '"'
    + ',sub_topic_name:"' + sub_topic_name + '"'
    + ',title:"' + addslashes(title) + '"'
    + '};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="description" content={description} />,
    <meta key='h-meta-2' property="og:title" content={title} />,
    <meta key='h-meta-3' property="og:description" content={description} />,
    <link key='h-canonical' rel="canonical" href={`${Consts.COMMON.BASE_URL}/${category}${topic_id?'/'+topic_id:''}`} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const mode = 'default';
  const page = 'article-index';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        custom_key_param={custom_key_param}
        helmet={helmet}
        header={
          <Header post_client={post_client} />
        }
        main={
          <>
            <Nav topic_id={category_topic_id} />
            <AdTop adid={adid.PC[adpagetype].BILLBOARD} device="pc" />
            <AdTop adid={adid.SP[adpagetype].BILLBOARD} device="sp" stub_bottom={category_topic_id === Consts.ID.AI}/>
            {category_topic_id === Consts.ID.AI && (
              <div className="p-article-index__ai">
                <div className="l-container">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="/images/contents_ai_sp@2x.jpg" />
                    <img src="/images/contents_ai@2x.jpg" alt="" className="" />
                  </picture>
                </div>
              </div>
            )}
            <ArticleList2ColumnWithSideBarBlock topic_id={target_topic_id} label={label} verification={data.verification} />
            <TopicsBlock />
            <RectangleAd adid={adid.SP[adpagetype].RECTANGLE2} viewable={true} device="sp" add_margin={true} />
            <RecommendBlock />
            <RectangleAd adid={adid.SP[adpagetype].RECTANGLE3} viewable={true} device="sp" add_margin={true} />
            <HtmlBanner device="sp" />
          </>
        }
        nikkei_footer = {
          category_topic_id === Consts.ID.AI ? (
            <NikkeiAiFooter />
          ) : (
            <NikkeiFooter />
          )
        }
      />
    </ThemeProvider>
  )
};

export default ArticleIndexPage;